import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../environments/environment';

import {
  LoginModel,
  DeviceModel,
  Pagination,
  PatternModel,
  Material,
} from '@3mk/shared/models';

import { RequestTypeEnum } from '@3mk/shared/enums/RequestType.enum';

import { ChangeStatusDto } from '../dto/change-status.dto';
import { MaterialGroup } from '@3mk/shared/models/MaterialGroup.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  logIn(payload: LoginModel) {
    return this.http.post(`${this.apiUrl}/auth/technician/logIn`, payload);
  }

  addDevice(
    payload: DeviceModel,
    pictureAllSafe: File,
    pictureFeryaCase: File,
    pictureFeryaCover: File,
    pictureAllBeta: File,
  ) {
    const formData = new FormData();
    formData.append('pictureAllSafe', pictureAllSafe);
    formData.append('pictureFeryaCase', pictureFeryaCase);
    formData.append('pictureFeryaCover', pictureFeryaCover);
    formData.append('pictureAllBeta', pictureAllBeta);
    formData.append('name', payload.name);
    formData.append('description', payload.description);
    formData.append(
      'isAvailableForAllSaveModule',
      payload.isAvailableForAllSaveModule ? '1' : '',
    );
    formData.append(
      'isAvailableForFeryaCaseModule',
      payload.isAvailableForFeryaCaseModule ? '1' : '',
    );

    formData.append(
      'isAvailableForFeryaCoverModule',
      payload.isAvailableForFeryaCoverModule ? '1' : '',
    );

    formData.append(
      'isAvailableForAllBetaModule',
      payload.isAvailableForAllBetaModule ? '1' : '',
    );

    formData.append(
      'isAvailableForOnDemandModule',
      payload.isAvailableForOnDemandModule ? '1' : '',
    );
    return this.http.post(`${this.apiUrl}/device`, formData);
  }

  getDevices(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }

    if (payload.sortKey) {
      params = params.append('sortField', payload.sortKey.field);
      params = params.append('sortValue', payload.sortKey.value);
    }
    return this.http.get(`${this.apiUrl}/device`, { params });
  }

  getOneDevice(id: string) {
    return this.http.get(`${this.apiUrl}/device/${id}`);
  }

  editDevice(
    payload: DeviceModel,
    pictureAllSafe: File,
    pictureFeryaCover: File,
    pictureFeryaCase: File,
    pictureAllBeta: File,
  ) {
    const formData = new FormData();
    formData.append('pictureAllSafe', pictureAllSafe);
    formData.append('pictureFeryaCase', pictureFeryaCase);
    formData.append('pictureFeryaCover', pictureFeryaCover);
    formData.append('pictureAllBeta', pictureAllBeta);
    formData.append('name', payload.name);
    formData.append('description', payload.description);
    formData.append(
      'isAvailableForAllSaveModule',
      payload.isAvailableForAllSaveModule ? '1' : '',
    );
    formData.append(
      'isAvailableForFeryaCoverModule',
      payload.isAvailableForFeryaCoverModule ? '1' : '',
    );

    formData.append(
      'isAvailableForFeryaCaseModule',
      payload.isAvailableForFeryaCaseModule ? '1' : '',
    );

    formData.append(
      'isAvailableForAllBetaModule',
      payload.isAvailableForAllBetaModule ? '1' : '',
    );

    formData.append(
      'isAvailableForOnDemandModule',
      payload.isAvailableForOnDemandModule ? '1' : '',
    );

    if (payload.comment) {
      formData.append('comment', payload.comment);
    }
    return this.http.put(`${this.apiUrl}/device/${payload.id}`, formData);
  }

  deleteDevice(id: string) {
    return this.http.delete(`${this.apiUrl}/device/${id}`);
  }

  getPatternTypes(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });
    if (payload.query) {
      params = params.append('query', payload.query);
    }
    return this.http.get(`${this.apiUrl}/patternType`, { params });
  }

  addPattern(payload: PatternModel, picture: File, plt: File, svg: File) {
    const formData = new FormData();
    formData.append('picture', picture);
    formData.append('plt', plt);
    formData.append('name', '');
    formData.append('svg', svg);
    formData.append('description', payload.description);
    formData.append('deviceId', payload.deviceId);
    formData.append('availableForModule', String(payload.availableForModule));

    formData.append('materialGroup', JSON.stringify(payload.materialGroup));
    return this.http.post(`${this.apiUrl}/pattern`, formData);
  }

  getDeviceAndPatterns(deviceId: string) {
    return this.http.get(`${this.apiUrl}/device/${deviceId}/patterns`);
  }

  deletePattern(id: string) {
    return this.http.delete(`${this.apiUrl}/pattern/${id}`);
  }

  getPattern(id: string) {
    return this.http.get(`${this.apiUrl}/pattern/${id}`);
  }

  editPattern(payload: PatternModel, picture: File, plt: File, svg: File) {
    const formData = new FormData();
    formData.append('plt', plt);
    formData.append('picture', picture);
    formData.append('svg', svg);
    formData.append('name', '');
    formData.append('description', payload.description);
    formData.append('deviceId', payload.deviceId);
    formData.append('id', payload.id);
    formData.append('availableForModule', String(payload.availableForModule));
    formData.append('materialGroup', JSON.stringify(payload.materialGroup));
    if (payload.comment) {
      formData.append('comment', payload.comment);
    }
    return this.http.put(`${this.apiUrl}/pattern/${payload.id}`, formData);
  }

  changePatternStatus(payload: ChangeStatusDto) {
    return this.http.put(
      `${this.apiUrl}/pattern/${payload.id}/changeStatus`,
      payload,
    );
  }

  getExchanges(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }

    if (payload.sortKey) {
      params = params.append('sortField', payload.sortKey.field);
      params = params.append('sortValue', payload.sortKey.value);
    }
    return this.http.get(`${this.apiUrl}/exchange`, { params });
  }

  getOneExchange(id: string) {
    return this.http.get(`${this.apiUrl}/exchange/${id}`);
  }

  getAllMaterials() {
    return this.http.get(`${this.apiUrl}/material/all/technician`);
  }

  getPatternMaterials(id: string) {
    return this.http.get(`${this.apiUrl}/pattern/${id}/materials`);
  }

  getAllMaterialGroups() {
    return this.http.get(`${this.apiUrl}/materialGroup/technician`);
  }

  getOneMaterialGroups(id: string) {
    return this.http.get(`${this.apiUrl}/materialGroup/${id}/technician`);
  }

  getServiceRequests(payload: Pagination, type?: RequestTypeEnum) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
        type: String(type),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }

    if (typeof payload.param !== 'undefined') {
      params = params.append('param', payload.param);
    }

    if (payload.sortKey) {
      params = params.append('sortField', payload.sortKey.field);
      params = params.append('sortValue', payload.sortKey.value);
    }
    return this.http.get(`${this.apiUrl}/serviceRequest`, { params });
  }

  confirmExamining(id: string) {
    return this.http.put(`${this.apiUrl}/serviceRequest`, { id: id });
  }

  getPatternListXls() {
    return this.http.get(`${this.apiUrl}/pattern/list/xls/technician`, {
      responseType: 'blob',
    });
  }

  getAssemblyErrors(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }

    if (payload.sortKey) {
      params = params.append('sortField', payload.sortKey.field);
      params = params.append('sortValue', payload.sortKey.value);
    }
    return this.http.get(`${this.apiUrl}/assembly-error`, { params });
  }

  getOneAssemblyError(id: string) {
    return this.http.get(`${this.apiUrl}/assembly-error/${id}`);
  }
}
