import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { LoginModel, DeviceModel, Pagination, PatternModel, } from '@3mk/shared/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    ApiService.prototype.logIn = function (payload) {
        return this.http.post(this.apiUrl + "/auth/technician/logIn", payload);
    };
    ApiService.prototype.addDevice = function (payload, pictureAllSafe, pictureFeryaCase, pictureFeryaCover, pictureAllBeta) {
        var formData = new FormData();
        formData.append('pictureAllSafe', pictureAllSafe);
        formData.append('pictureFeryaCase', pictureFeryaCase);
        formData.append('pictureFeryaCover', pictureFeryaCover);
        formData.append('pictureAllBeta', pictureAllBeta);
        formData.append('name', payload.name);
        formData.append('description', payload.description);
        formData.append('isAvailableForAllSaveModule', payload.isAvailableForAllSaveModule ? '1' : '');
        formData.append('isAvailableForFeryaCaseModule', payload.isAvailableForFeryaCaseModule ? '1' : '');
        formData.append('isAvailableForFeryaCoverModule', payload.isAvailableForFeryaCoverModule ? '1' : '');
        formData.append('isAvailableForAllBetaModule', payload.isAvailableForAllBetaModule ? '1' : '');
        formData.append('isAvailableForOnDemandModule', payload.isAvailableForOnDemandModule ? '1' : '');
        return this.http.post(this.apiUrl + "/device", formData);
    };
    ApiService.prototype.getDevices = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        if (payload.sortKey) {
            params = params.append('sortField', payload.sortKey.field);
            params = params.append('sortValue', payload.sortKey.value);
        }
        return this.http.get(this.apiUrl + "/device", { params: params });
    };
    ApiService.prototype.getOneDevice = function (id) {
        return this.http.get(this.apiUrl + "/device/" + id);
    };
    ApiService.prototype.editDevice = function (payload, pictureAllSafe, pictureFeryaCover, pictureFeryaCase, pictureAllBeta) {
        var formData = new FormData();
        formData.append('pictureAllSafe', pictureAllSafe);
        formData.append('pictureFeryaCase', pictureFeryaCase);
        formData.append('pictureFeryaCover', pictureFeryaCover);
        formData.append('pictureAllBeta', pictureAllBeta);
        formData.append('name', payload.name);
        formData.append('description', payload.description);
        formData.append('isAvailableForAllSaveModule', payload.isAvailableForAllSaveModule ? '1' : '');
        formData.append('isAvailableForFeryaCoverModule', payload.isAvailableForFeryaCoverModule ? '1' : '');
        formData.append('isAvailableForFeryaCaseModule', payload.isAvailableForFeryaCaseModule ? '1' : '');
        formData.append('isAvailableForAllBetaModule', payload.isAvailableForAllBetaModule ? '1' : '');
        formData.append('isAvailableForOnDemandModule', payload.isAvailableForOnDemandModule ? '1' : '');
        if (payload.comment) {
            formData.append('comment', payload.comment);
        }
        return this.http.put(this.apiUrl + "/device/" + payload.id, formData);
    };
    ApiService.prototype.deleteDevice = function (id) {
        return this.http.delete(this.apiUrl + "/device/" + id);
    };
    ApiService.prototype.getPatternTypes = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/patternType", { params: params });
    };
    ApiService.prototype.addPattern = function (payload, picture, plt, svg) {
        var formData = new FormData();
        formData.append('picture', picture);
        formData.append('plt', plt);
        formData.append('name', '');
        formData.append('svg', svg);
        formData.append('description', payload.description);
        formData.append('deviceId', payload.deviceId);
        formData.append('availableForModule', String(payload.availableForModule));
        formData.append('materialGroup', JSON.stringify(payload.materialGroup));
        return this.http.post(this.apiUrl + "/pattern", formData);
    };
    ApiService.prototype.getDeviceAndPatterns = function (deviceId) {
        return this.http.get(this.apiUrl + "/device/" + deviceId + "/patterns");
    };
    ApiService.prototype.deletePattern = function (id) {
        return this.http.delete(this.apiUrl + "/pattern/" + id);
    };
    ApiService.prototype.getPattern = function (id) {
        return this.http.get(this.apiUrl + "/pattern/" + id);
    };
    ApiService.prototype.editPattern = function (payload, picture, plt, svg) {
        var formData = new FormData();
        formData.append('plt', plt);
        formData.append('picture', picture);
        formData.append('svg', svg);
        formData.append('name', '');
        formData.append('description', payload.description);
        formData.append('deviceId', payload.deviceId);
        formData.append('id', payload.id);
        formData.append('availableForModule', String(payload.availableForModule));
        formData.append('materialGroup', JSON.stringify(payload.materialGroup));
        if (payload.comment) {
            formData.append('comment', payload.comment);
        }
        return this.http.put(this.apiUrl + "/pattern/" + payload.id, formData);
    };
    ApiService.prototype.changePatternStatus = function (payload) {
        return this.http.put(this.apiUrl + "/pattern/" + payload.id + "/changeStatus", payload);
    };
    ApiService.prototype.getExchanges = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        if (payload.sortKey) {
            params = params.append('sortField', payload.sortKey.field);
            params = params.append('sortValue', payload.sortKey.value);
        }
        return this.http.get(this.apiUrl + "/exchange", { params: params });
    };
    ApiService.prototype.getOneExchange = function (id) {
        return this.http.get(this.apiUrl + "/exchange/" + id);
    };
    ApiService.prototype.getAllMaterials = function () {
        return this.http.get(this.apiUrl + "/material/all/technician");
    };
    ApiService.prototype.getPatternMaterials = function (id) {
        return this.http.get(this.apiUrl + "/pattern/" + id + "/materials");
    };
    ApiService.prototype.getAllMaterialGroups = function () {
        return this.http.get(this.apiUrl + "/materialGroup/technician");
    };
    ApiService.prototype.getOneMaterialGroups = function (id) {
        return this.http.get(this.apiUrl + "/materialGroup/" + id + "/technician");
    };
    ApiService.prototype.getServiceRequests = function (payload, type) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
                type: String(type),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        if (typeof payload.param !== 'undefined') {
            params = params.append('param', payload.param);
        }
        if (payload.sortKey) {
            params = params.append('sortField', payload.sortKey.field);
            params = params.append('sortValue', payload.sortKey.value);
        }
        return this.http.get(this.apiUrl + "/serviceRequest", { params: params });
    };
    ApiService.prototype.confirmExamining = function (id) {
        return this.http.put(this.apiUrl + "/serviceRequest", { id: id });
    };
    ApiService.prototype.getPatternListXls = function () {
        return this.http.get(this.apiUrl + "/pattern/list/xls/technician", {
            responseType: 'blob',
        });
    };
    ApiService.prototype.getAssemblyErrors = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        if (payload.sortKey) {
            params = params.append('sortField', payload.sortKey.field);
            params = params.append('sortValue', payload.sortKey.value);
        }
        return this.http.get(this.apiUrl + "/assembly-error", { params: params });
    };
    ApiService.prototype.getOneAssemblyError = function (id) {
        return this.http.get(this.apiUrl + "/assembly-error/" + id);
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
